import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const top100Films = [
  { label: "Làm đẹp", code: 'lam-dep' },
   { label: "Giải trí", code: 'giai-tri' },
  { label: "Sức khỏe", code: 'suc-khoe' },
  { label: "Thương mại điện tử", code: 'thuong-mai-dien-tu' },
  { label: "Công nghệ", code: 'cong-nghe' },
  { label: "F&B", code: 'fb' },
  { label: "Xây dựng", code: 'xay-dung' },
];
function KhachHangPage() {
  const [valueData, setValueData] = useState("");
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [page, setPage] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [openNotification, setOpenNotification] = React.useState(false);
  const [valueName, setValueName] = useState("");
  const [valueEmail, setValueEmail] = useState("");
  const [valueSex, setValueSex] = useState("");
  const [valuePhone, setValuePhone] = useState("");
  const [valueBirthday, setValueBirthday] = useState("");
  const [valueAddress, setValueAddress] = useState("");
  const [valueBusiness, setValueBusiness] = useState("");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    getAllCustomer();
  }, []);
  const getAllCustomer = () => {
    const dataBody = {
      key: "XsJKDd8NQ=}2eAcSmxc-ngwR/Hf-Hh9M",
    };
    fetch("https://tmsoftware.vn/Admin/API/Customer/select.php", {
      method: "POST",
      body: JSON.stringify(dataBody),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((result) => {
        setValueData(result);
        console.log(result);
      })
      .catch((error) => {});
  };
  const generateRandomString = (length) => {
    const characters = "0123456789";
    let result = "";
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters.charAt(randomIndex);
    }
    return result;
  };
  const saveCustomer = () => {
    let code = generateRandomString(10);
    const dataBody = {
      key: "XsJKDd8NQ=}2eAcSmxc-ngwR/Hf-Hh9M",
      code:code,
      name:valueName,
      phone:valuePhone,
      email:valueEmail,
      sex:valueSex,
      birthday:valueBirthday,
      business:valueBusiness,
      address:valueAddress,
    };
    fetch("https://tmsoftware.vn/Admin/API/Customer/add.php", {
      method: "POST",
      body: JSON.stringify(dataBody),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((result) => {
        setOpen(false);

       // setValueData(result);
       getAllCustomer();
        console.log(result);
      })
      .catch((error) => {});
  };

  const deleteCustomer =(event,newValue)=>{
    let code = newValue.codeCustomer;
    const dataBody = {
      key: "XsJKDd8NQ=}2eAcSmxc-ngwR/Hf-Hh9M",
      code:code,
    };
    fetch("https://tmsoftware.vn/Admin/API/Customer/delete.php", {
      method: "POST",
      body: JSON.stringify(dataBody),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((result) => {
        //setOpen(false);
        setOpenNotification(true);
       // setValueData(result);
       getAllCustomer();
        console.log(result);
      })
      .catch((error) => {});
  }
  return (
    <div className="page">
      <Box sx={{ flexGrow: 1 }}>
        <AppBar
          position="static"
          style={{ backgroundColor: "white", color: "black" }}
        >
          <Toolbar variant="dense">
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              Danh sách khách hàng
            </Typography>
            <Button color="inherit" onClick={handleClickOpen}>
              Thêm
            </Button>
          </Toolbar>
        </AppBar>
      </Box>
      <Paper sx={{ width: "100%" }}>
        <TableContainer sx={{ height: "83vh" }}>
          <Table
            stickyHeader
            aria-label="sticky table"
            id="table-to-xls-location"
          >
            <TableHead>
              <TableRow>
                <TableCell
                  align="left"
                  sx={{
                    width: 30,
                    backgroundColor: "teal",
                    color: "white",
                    padding: "7px",
                  }}
                >
                  STT
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    minWidth: 130,
                    backgroundColor: "teal",
                    color: "white",
                    padding: "7px",
                  }}
                >
                  Tên khách hàng
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    width: 70,
                    backgroundColor: "teal",
                    color: "white",
                    padding: "7px",
                  }}
                >
                  Phone
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    width: 50,
                    backgroundColor: "teal",
                    color: "white",
                    padding: "5px",
                  }}
                >
                  Sex
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    width: 50,
                    backgroundColor: "teal",
                    color: "white",
                    padding: "7px",
                  }}
                >
                  Email
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    width: 90,
                    backgroundColor: "teal",
                    color: "white",
                    padding: "7px",
                  }}
                >
                  Ngày sinh
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    minWidth: 110,
                    backgroundColor: "teal",
                    color: "white",
                    padding: "7px",
                  }}
                >
                  Địa chỉ
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    width: 50,
                    backgroundColor: "teal",
                    color: "white",
                    padding: "7px",
                  }}
                >
                  Hành động
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {valueData === "" ? (
                <div></div>
              ) : (
                valueData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}
                      >
                        <TableCell key={row.id} style={{ padding: "8px" }}>
                          {row.id}
                        </TableCell>
                        <TableCell key={row.id} style={{ padding: "8px" }}>
                          {row.name}
                        </TableCell>
                        <TableCell key={row.id} style={{ padding: "8px" }}>
                          {row.phone}
                        </TableCell>
                        <TableCell key={row.id} style={{ padding: "8px" }}>
                          {row.sex}
                        </TableCell>
                        <TableCell key={row.id} style={{ padding: "8px" }}>
                          {row.email}
                        </TableCell>
                        <TableCell key={row.id} style={{ padding: "8px" }}>
                          {row.birthday}
                        </TableCell>
                        <TableCell key={row.id} style={{ padding: "8px" }}>
                          {row.address}
                        </TableCell>
                        <TableCell key={row.id} style={{ padding: "8px" }}>
                          {/* <IconButton aria-label="delete" size="small">
                              <DeleteIcon fontSize="inherit" />
                            </IconButton> */}
                          <div
                            style={{
                              display: "flex",
                              width: "100%",
                            }}
                          >
                            <button
                              className="icon-button-edit"
                              style={{ marginRight: "5px" }}
                            >
                              Edit
                            </button>
                            <button className="icon-button-delete" onClick={(event)=>{
                              deleteCustomer(event,row);
                            }}>
                              Delete
                            </button>
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={valueData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>

      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Thêm Thông tin khách hàng
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <div style={{height:'300px'}}>
            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "8px" }}>
                <div style={{ marginBottom: "5px" }}>Tên khách hàng</div>
                <TextField
                  id="outlined-basic"
                  placeholder="Tên khách hàng"
                  size="small"
                  value={valueName}
                  variant="outlined"
                  style={{ width: "250px" }}
                  onChange={(event) => {
                    setValueName(event.target.value);
                  }}
                />
              </div>
              <div >
                <div style={{ marginBottom: "5px" }}>Số điện thoại</div>
                <TextField
                  id="outlined-basic"
                  value={valuePhone}
                  placeholder="Số điện thoại"
                  size="small"
                  variant="outlined"
                  style={{ width: "300px" }}
                  onChange={(event) => {
                    setValuePhone(event.target.value);
                  }}
                />
              </div>
            </div>
            <div style={{ display: "flex", marginTop: "10px" }}>
              <div style={{ marginRight: "8px" }}>
                <div style={{ marginBottom: "5px" }}> Ngành nghề</div>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={top100Films}
                  onChange={(event)=>{
                    setValueBusiness(event.target.value);
                  }}
                  size="small"
                  sx={{ width: 250 }}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Ngành nghề" />
                  )}
                />
              </div>
              <div style={{ marginRight: "8px" }}>
                <div style={{ marginBottom: "5px" }}>Email</div>
                <TextField
                  id="outlined-basic"
                  value={valueEmail}
                  placeholder="Email"
                  size="small"
                  variant="outlined"
                  style={{ width: "200px" }}
                  onChange={(event) => {
                    setValueEmail(event.target.value);
                  }}
                />
              </div>
              <div>
                <div style={{ marginBottom: "5px" }}>Giới tính</div>
                <TextField
                  id="outlined-basic"
                  placeholder="Giới tính"
                  size="small"
                  value={valueSex}
                  variant="outlined"
                  style={{ width: "90px" }}
                  onChange={(event) => {
                    setValueSex(event.target.value);
                  }}
                />
              </div>
            </div>
            <div style={{ display: "flex", marginTop: "10px" }}>
              <div style={{ marginRight: "8px" }}>
                <div style={{ marginBottom: "5px" }}>Ngày sinh</div>
                <TextField
                  id="outlined-basic"
                  value={valueBirthday}
                  placeholder="Số điện thoại"
                  size="small"
                  variant="outlined"
                  style={{ width: "150px" }}
                  onChange={(event) => {
                    setValueBirthday(event.target.value);
                  }}
                />
              </div>
              <div>
                <div style={{ marginBottom: "5px" }}>Địa chỉ</div>
                <TextField
                  id="outlined-basic"
                  value={valueAddress}
                  placeholder="Địa chỉ"
                  size="small"
                  variant="outlined"
                  style={{ width: "400px" }}
                  onChange={(event) => {
                    setValueAddress(event.target.value);
                  }}
                />
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={saveCustomer}>
            Save
          </Button>
        </DialogActions>
      </BootstrapDialog>

      <Snackbar open={openNotification}  anchorOrigin={{ vertical:'top', horizontal:'right' }} autoHideDuration={6000} onClose={handleClose}>
      <Alert severity="success">Xóa thông tin khách hàng thành công!</Alert>
      </Snackbar>
    </div>
  );
}
export default KhachHangPage;

/* eslint-disable no-const-assign */
import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { NumericFormat } from "react-number-format";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
const modules = {
  toolbar: [
    [{ font: [] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["bold", "italic", "underline", "strike"],
    [{ color: [] }, { background: [] }],
    [{ script: "sub" }, { script: "super" }],
    ["blockquote", "code-block"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link", "image", "video", "formula"],
    ["clean"],
  ],
};

const NumericFormatCustom = React.forwardRef(function NumericFormatCustom(
  props,
  ref
) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      valueIsNumericString
    />
  );
});
function OrderPage() {
  const [valueDataOrder, setValueDataOrder] = useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [valueDataCustomer, setValueDataCustomer] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [openDetail, setOpenDetail] = React.useState(false);
  const [selectedDate, setSelectedDate] = useState(dayjs(new Date()));
  const [selectedDatePay, setSelectedDatePay] = useState(dayjs(new Date()));
  const [selectedDateEnd, setSelectedDateEnd] = useState(dayjs(new Date()));
  const [editorHtml1, setEditorHtml1] = useState("");
  const [valueContentOrder, setValueContentOrder] = useState("");
  const [valueCustomerOrder, setValueCustomerOrder] = useState("");
  const [valueCodeOrder, setValueCodeOrder] = useState("");
  const [valueDataPay, setValueDataPay] = React.useState([]);
  const [values, setValues] = React.useState({
    textmask: "0",
    numberformat: "1320",
  });
  const [valuesPay, setValuesPay] = React.useState({
    textmask: "0",
    numberformat: "1320",
  });
  const [openLoading, setOpenLoading] = React.useState(false);
  const [openSnack, setOpenSnack] = React.useState(false);
  const [openPay, setOpenPay] = React.useState(false);
  const handleCloseLoading = () => {
    setOpenLoading(false);
  };

  const handleChange = (event) => {
    setValues({
      ...values,
      textmask: event.target.value,
    });
  };
  const handleChangePay = (event) => {
    setValuesPay({
      ...valuesPay,
      textmask: event.target.value,
    });
  };

  function formatCurrency(amount) {
    return amount.toLocaleString("vi-VN", {
      style: "currency",
      currency: "VND",
    });
  }
  const handleTextDe = (value) => {
    setEditorHtml1(value);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    getAllOrder();
    getAllCustomer();
  }, []);
  const getAllCustomer = () => {
    const dataBody = {
      key: "XsJKDd8NQ=}2eAcSmxc-ngwR/Hf-Hh9M",
    };
    fetch("https://tmsoftware.vn/Admin/API/Customer/select.php", {
      method: "POST",
      body: JSON.stringify(dataBody),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((result) => {
        setValueDataCustomer(result);
        //console.log(result);
      })
      .catch((error) => {});
  };

  const getAllOrder = () => {
    const dataBody = {
      key: "XsJKDd8NQ=}2eAcSmxc-ngwR/Hf-Hh9M",
    };
    fetch("https://tmsoftware.vn/Admin/API/Order/select.php", {
      method: "POST",
      body: JSON.stringify(dataBody),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((result) => {
        setValueDataOrder(result);
        console.log(result);
      })
      .catch((error) => {});
  };

  const saveOrder = () => {
    setOpen(false);
    setOpenLoading(true);
    let codeDh = valueCodeOrder;
    if (codeDh === "") {
      codeDh ="DH-"+generateRandomString(10);
    }

   
    const money = values.textmask;
    const prepay = valuesPay.textmask;
    const dataBody = {
      key: "XsJKDd8NQ=}2eAcSmxc-ngwR/Hf-Hh9M",
      code: codeDh,
      content: valueContentOrder,
      customer: valueCustomerOrder,
      money: money,
      prepay: prepay,
      datePay: selectedDatePay,
      timerStart: selectedDate,
      timerEnd: selectedDateEnd,
      detail: editorHtml1,
    };
    fetch("https://tmsoftware.vn/Admin/API/Order/add.php", {
      method: "POST",
      body: JSON.stringify(dataBody),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((result) => {
        // setValueDataCustomer(result);
        setOpenLoading(false);
        setOpenSnack(true);
        getAllOrder();
        console.log(result);
      })
      .catch((error) => {});
  };
  const clickEditEvent = (event, newValue) => {
    setValueCodeOrder(newValue.order_Code);
    setValueContentOrder(newValue.order_Name);
    setValueCustomerOrder(newValue.order_Customer);
    setEditorHtml1(newValue.order_Detail);
    setSelectedDate(dayjs(new Date(newValue.order_Tmer_Start)));
    setSelectedDateEnd(dayjs(new Date(newValue.order_Timer_End)));
    setValues({
      ...values,
      textmask: newValue.order_Money,
    });

    setOpen(true);
  };
  const generateRandomString = (length) => {
    const characters = "0123456789";
    let result = "";
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters.charAt(randomIndex);
    }
    return result;
  };

  //const [valueDataPay, setValueDataPay] = useState("");
  const [valueNgayGiao, setValueNgayGiao] = useState("");
  const [valueNgayHetHan, setValueNgayHetHan] = useState("");
  const getDetail = async (event, newValue) => {
    setValueCodeOrder(newValue.order_Code);
    setValueContentOrder(newValue.order_Name);
    setValueCustomerOrder(newValue.order_Customer);
    setEditorHtml1(newValue.order_Detail);
    setValueNgayGiao(newValue.order_Tmer_Start);
    setValueNgayHetHan(newValue.order_Timer_End);
    setValueDataPay(newValue.data_pay);
    //setSelectedDate(dayjs(new Date(newValue.order_Tmer_Start)));
    //setSelectedDateEnd(dayjs(new Date(newValue.order_Timer_End)));
    setValues({
      ...values,
      textmask: newValue.order_Money,
    });
    setOpenDetail(true);
  };

  const closeOpenDetail = () => {
    setOpenDetail(false);
  };
 const [valueNo,setValueNo] = useState("");
  const eventOpenPay = (event, newValue) => {
    setValueCodeOrder(newValue.order_Code);
    setValueCustomerOrder(newValue.order_Customer);
    setValues({
      ...values,
      textmask: newValue.order_Money,
    });
    setValueNo( parseFloat(newValue.order_Money) -  parseFloat(newValue.totalMoney));
    setOpenPay(true);
  };
  const handleClosePay = () => {
    setOpenPay(false);
  };

  const savePay = () => {
    const codeDh = valueCodeOrder;
    setOpenPay(false);
    setOpenLoading(true);
    const prepay = valuesPay.textmask;
    const dataBody = {
      key: "XsJKDd8NQ=}2eAcSmxc-ngwR/Hf-Hh9M",
      code: codeDh,
      prepay: prepay,
      datePay: selectedDatePay,
    };
    fetch("https://tmsoftware.vn/Admin/API/Order/add_Pay.php", {
      method: "POST",
      body: JSON.stringify(dataBody),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((result) => {

        // setValueDataCustomer(result);
        setOpenLoading(false);
        setOpenSnack(true);
        getAllOrder();

        setValueCodeOrder("");
        setValueContentOrder("");
        setValueCustomerOrder("");
        setValues({
          ...values,
          textmask: "0",
        });

        console.log(result);
      })
      .catch((error) => {});
  };
  return (
    <div className="page">
      <Box sx={{ flexGrow: 1 }}>
        <AppBar
          position="static"
          style={{ backgroundColor: "white", color: "black" }}
        >
          <Toolbar variant="dense">
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              Đơn hàng
            </Typography>
            <Button color="inherit" onClick={handleClickOpen}>
              Thêm
            </Button>
          </Toolbar>
        </AppBar>
      </Box>
      <Paper sx={{ width: "100%" }}>
        <TableContainer sx={{ height: "83vh" }}>
          <Table
            stickyHeader
            aria-label="sticky table"
            id="table-to-xls-location"
          >
            <TableHead>
              <TableRow>
                <TableCell
                  align="left"
                  sx={{
                    width: 40,
                    backgroundColor: "teal",
                    color: "white",
                    padding: "7px",
                  }}
                >
                  STT
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    minWidth: 180,
                    backgroundColor: "teal",
                    color: "white",
                    padding: "7px",
                  }}
                >
                  Nội dung
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    minWidth: 150,
                    backgroundColor: "teal",
                    color: "white",
                    padding: "7px",
                  }}
                >
                  Khách hàng
                </TableCell>
                <TableCell
                  align="right"
                  sx={{
                    width: 90,
                    backgroundColor: "teal",
                    color: "white",
                    padding: "5px",
                  }}
                >
                  Tổng tiền
                </TableCell>
                <TableCell
                  align="right"
                  sx={{
                    width: 110,
                    backgroundColor: "teal",
                    color: "white",
                    padding: "7px",
                  }}
                >
                  Thanh toán
                </TableCell>
                <TableCell
                  align="right"
                  sx={{
                    width: 110,
                    backgroundColor: "teal",
                    color: "white",
                    padding: "7px",
                  }}
                >
                  Nợ
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    width: 90,
                    backgroundColor: "teal",
                    color: "white",
                    padding: "7px",
                  }}
                >
                  Hết hạn
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    width: 130,
                    backgroundColor: "teal",
                    color: "white",
                    padding: "7px",
                  }}
                >
                  Hành động
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {valueDataOrder.length === 0 ? (
                <div></div>
              ) : (
                valueDataOrder
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.code}
                      >
                        <TableCell key={row.id} style={{ padding: "5px" }}>
                          {row.id}
                        </TableCell>
                        <TableCell key={row.id} style={{ padding: "5px" }}>
                          {row.order_Name}
                        </TableCell>
                        <TableCell key={row.id} style={{ padding: "5px" }}>
                          {row.order_Customer}
                        </TableCell>
                        <TableCell
                          key={row.id}
                          align="right"
                          style={{ padding: "5px" }}
                        >
                          {formatCurrency(parseFloat(row.order_Money))}
                        </TableCell>
                        <TableCell
                          key={row.id}
                          align="right"
                          style={{ padding: "5px" }}
                        >
                          {formatCurrency(parseFloat(row.totalMoney))}
                        </TableCell>
                        <TableCell
                          key={row.id}
                          align="right"
                          style={{ padding: "5px" }}
                        >
                          {formatCurrency(
                            parseFloat(row.order_Money) -
                              parseFloat(row.totalMoney)
                          )}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            width: 90,
                          }}
                        >
                          {row.order_Timer_End}
                        </TableCell>
                        <TableCell key={row.id} style={{ padding: "5px" }}>
                          <div style={{ display: "flex" }}>
                            {parseFloat(row.order_Money) -
                              parseFloat(row.totalMoney) ===
                            0 ? (
                              <div
                                style={{ width: "82px", marginRight: "5px" }}
                              ></div>
                            ) : (
                              <button
                                className="icon-button-pay"
                                onClick={(event) => {
                                  eventOpenPay(event, row);
                                }}
                              >
                                Thanh toán
                              </button>
                            )}

                            <button
                              className="icon-button-more"
                              onClick={(event) => {
                                getDetail(event, row);
                              }}
                            >
                              Chi tiết
                            </button>
                            <button
                              className="icon-button-edit"
                              onClick={(event) => {
                                clickEditEvent(event, row);
                              }}
                            >
                              Edit
                            </button>
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[15, 30, 50]}
          component="div"
          count={valueDataOrder.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>

      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Thêm đơn hàng
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <div>
            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "8px" }}>
                <div style={{ marginBottom: "5px", color: "blue" }}>
                  Nội dung
                </div>
                <TextField
                  id="outlined-basic"
                  placeholder="Nội dung"
                  variant="outlined"
                  value={valueContentOrder}
                  size="small"
                  style={{ width: "280px" }}
                  onChange={(event) => {
                    setValueContentOrder(event.target.value);
                  }}
                />
              </div>
              <div>
                <div style={{ marginBottom: "5px", color: "blue" }}>
                  Khách hàng
                </div>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  size="small"
                  value={valueCustomerOrder}
                  options={
                    valueDataCustomer.length === 0
                      ? []
                      : valueDataCustomer.map((item) => item.name)
                  }
                  sx={{ width: 270 }}
                  onChange={(event, newValue) => {
                    setValueCustomerOrder(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Khách hàng" />
                  )}
                />
              </div>
            </div>

            <div style={{ display: "flex", marginTop: "10px" }}>
              <div style={{ display: "flex", marginRight: "10px" }}>
                <div style={{ marginRight: "8px" }}>
                  <div style={{ marginBottom: "5px", color: "blue" }}>Tiền</div>
                  <TextField
                    id="outlined-money"
                    placeholder="Tiền"
                    variant="outlined"
                    size="small"
                    value={values.textmask}
                    onChange={handleChange}
                    style={{ width: "135px" }}
                    InputProps={{
                      inputComponent: NumericFormatCustom,
                    }}
                  />
                </div>
                <div>
                  <div style={{ marginBottom: "5px", color: "blue" }}>
                    Thanh toán
                  </div>
                  <TextField
                    id="outlined-Prepay"
                    placeholder="Thanh toán"
                    variant="outlined"
                    size="small"
                    value={valuesPay.textmask}
                    onChange={handleChangePay}
                    style={{ width: "135px" }}
                    InputProps={{
                      inputComponent: NumericFormatCustom,
                    }}
                  />
                </div>
              </div>
              <div>
                <div style={{ marginBottom: "5px", color: "blue" }}>
                  Ngày thanh toán
                </div>
                <div style={{ display: "flex" }}>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    //adapterLocale={"en-gb"}
                  >
                    <DatePicker
                      format="DD/MM/YYYY"
                      value={selectedDatePay}
                      onChange={(newValue) => {
                        setSelectedDatePay(newValue);
                      }}
                      slotProps={{
                        textField: { size: "small", style: { width: 270 } },
                      }}
                    />
                  </LocalizationProvider>
                </div>
              </div>
            </div>
            <div style={{ marginTop: "10px", display: "flex" }}>
              <div style={{ marginRight: "10px" }}>
                <div style={{ marginBottom: "5px", color: "blue" }}>
                  Ngày giao hàng
                </div>
                <div style={{ display: "flex" }}>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    //adapterLocale={"en-gb"}
                  >
                    <DatePicker
                      format="DD/MM/YYYY"
                      value={selectedDate}
                      onChange={(newValue) => {
                        setSelectedDate(newValue);
                      }}
                      slotProps={{
                        textField: { size: "small", style: { width: 280 } },
                      }}
                    />
                  </LocalizationProvider>
                </div>
              </div>
              <div>
                <div style={{ marginBottom: "5px", color: "blue" }}>
                  Kết thúc chương trình - Bảo hành
                </div>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  //adapterLocale={"en-gb"}
                >
                  <DatePicker
                    format="DD/MM/YYYY"
                    value={selectedDateEnd}
                    onChange={(newValue) => {
                      setSelectedDateEnd(newValue);
                    }}
                    slotProps={{
                      textField: { size: "small", style: { width: 270 } },
                    }}
                  />
                </LocalizationProvider>
              </div>
            </div>
            <div style={{ marginTop: "10px" }}>
              <div style={{ marginBottom: "5px", color: "blue" }}>Chi tiết</div>
              <ReactQuill
                fullScreen
                modules={modules}
                style={{ height: 300 }}
                value={editorHtml1}
                onChange={handleTextDe}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={saveOrder}>
            Save
          </Button>
        </DialogActions>
      </BootstrapDialog>
      <BootstrapDialog
        aria-labelledby="customized-dialog-title"
        open={openDetail}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Chi tiết đơn hàng
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={closeOpenDetail}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers style={{ width: "400px" }}>
          <div>
            <div style={{ marginBottom: "8px" }}>
              Nội dung : {valueContentOrder}
            </div>
            <div style={{ marginBottom: "8px" }}>
              Khách hàng : {valueCustomerOrder}
            </div>
            <div style={{ marginBottom: "8px" }}>
              Tiền : {formatCurrency(parseFloat(values.textmask))}
            </div>
            <div style={{ marginBottom: "8px" }}>
              Ngày giao : {valueNgayGiao}
            </div>
            <div style={{ marginBottom: "8px" }}>
              Ngày hết hạn : {valueNgayHetHan}
            </div>
            <div style={{ marginBottom: "8px" }}>
              <div>Thanh toán</div>
              {valueDataPay.map((item) =>
                item.order_Money_Pay === "0" ? (
                  <></>
                ) : (
                  <div style={{ display: "flex" }}>
                    <div style={{ width: "30px" }}>-</div>
                    <div style={{ width: "120px" }}>
                      {" "}
                      {item.order_Date_Pay}{" "}
                    </div>
                    <div>
                      {" "}
                      {formatCurrency(parseFloat(item.order_Money_Pay))}{" "}
                    </div>
                  </div>
                )
              )}
            </div>
            <div>
              <div>Chi tiết</div>
              <div
                dangerouslySetInnerHTML={{
                  __html: editorHtml1,
                }}
              ></div>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={closeOpenDetail}>
            Đóng
          </Button>
        </DialogActions>
      </BootstrapDialog>

      <BootstrapDialog aria-labelledby="customized-dialog-title" open={openPay}>
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Thanh toán
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClosePay}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers style={{ width: "350px" }}>
          <div>
          <div style={{ marginBottom: "8px" }}>
              Khách hàng : {valueCustomerOrder}
            </div>
            <div style={{ marginBottom: "8px" }}>
              Tiền : {formatCurrency(parseFloat(values.textmask))}
            </div>
            <div style={{ marginBottom: "8px" }}>
              Nợ : {formatCurrency(parseFloat(valueNo))}
            </div>
            <div style={{ display: "flex" }}>
              <div style={{marginRight:'10px'}}>
                <div style={{ marginBottom: "5px", color: "blue" }}>
                  Ngày thanh toán
                </div>
                <div style={{ display: "flex" }}>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    //adapterLocale={"en-gb"}
                  >
                    <DatePicker
                      format="DD/MM/YYYY"
                      value={selectedDatePay}
                      onChange={(newValue) => {
                        setSelectedDatePay(newValue);
                      }}
                      slotProps={{
                        textField: { size: "small", style: { width: 150 } },
                      }}
                    />
                  </LocalizationProvider>
                </div>
              </div>
              <div>
                  <div style={{ marginBottom: "5px", color: "blue" }}>
                    Thanh toán
                  </div>
                  <TextField
                    id="outlined-Prepay"
                    placeholder="Thanh toán"
                    variant="outlined"
                    size="small"
                    value={valuesPay.textmask}
                    onChange={handleChangePay}
                    style={{ width: "160px" }}
                    InputProps={{
                      inputComponent: NumericFormatCustom,
                    }}
                  />
                </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={savePay}>
            Save
          </Button>
        </DialogActions>
      </BootstrapDialog>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openLoading}
        onClick={handleCloseLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>


      <Snackbar open={openSnack}  anchorOrigin={{ vertical:'top', horizontal:'right' }} autoHideDuration={6000} onClose={handleClose}>
      <Alert severity="success">Thành công!</Alert>
      </Snackbar>
      {/* <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={openSnack}
        style={{
          backgroundColor: "green",
        }}
        onClose={() => {
          setOpenSnack(false);
        }}
        message="Thành công"
        key={"top-right"}
      /> */}
    </div>
  );
}
export default OrderPage;

import React, { useEffect, useState } from "react";
import { axisClasses } from "@mui/x-charts";
import { BarChart } from "@mui/x-charts/BarChart";
// import { Bar } from "react-chartjs-2";


function DashBoardPage() {
  const [valueDoanhThu, setValueDoanhThu] = useState([]);

  useEffect(() => {
    getDoanhThu();
  }, []);
  const getDoanhThu = () => {
    const dataBody = {
      key: "XsJKDd8NQ=}2eAcSmxc-ngwR/Hf-Hh9M",
      nam: "2023",
    };
    fetch("https://tmsoftware.vn/Admin/API/Dashboard/select.php", {
      method: "POST",
      body: JSON.stringify(dataBody),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((result) => {
        setValueDoanhThu(result);
        console.log(result);
      })
      .catch((error) => {});
  };


  return (
    <div className="page" style={{ marginLeft: "190px" }}>
      {valueDoanhThu.length === 0 ? (
        <div></div>
      ) : (
       
    
          <BarChart
            xAxis={[
              {
                id: "barCategories",
                data: valueDoanhThu.map((item) => "Tháng"+item.thang),
                scaleType: "band",
                
              },
            ]}
           
            yAxis={[
                {
                    id:'y-data',
                    tickSize:200,
                   label:"Triệu"
                }

            ]}
            series={[
              {
                data: valueDoanhThu.map((item) => (item.money/1000000)),
                label:"Doanh thu"
                
              },
            ]}
             
            sx={{transform: 'translate(50px, 10px)',}}
             width={(valueDoanhThu.length +1) * 75}
             height={400}
          />
        
      )}
    </div>
  );
}

export default DashBoardPage;


import React,{useState,useEffect} from "react";
// import { useMediaQuery } from "react-responsive";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Box from "@mui/material/Box";
import DashboardIcon from "@mui/icons-material/Dashboard";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import { styled } from "@mui/material/styles";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import RestoreIcon from "@mui/icons-material/Restore";
import Paper from "@mui/material/Paper";
import ArchiveIcon from "@mui/icons-material/Archive";
import LibraryAddCheckIcon from "@mui/icons-material/LibraryAddCheck";
import LocalMallIcon from "@mui/icons-material/LocalMall";
import ConnectedTvIcon from "@mui/icons-material/ConnectedTv";
import Dashboard from "@mui/icons-material/Dashboard";
import MenuIcon from "@mui/icons-material/Menu";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import AssessmentIcon from "@mui/icons-material/Assessment";
import SupportAgentOutlinedIcon from "@mui/icons-material/SupportAgentOutlined";
import VerifiedOutlinedIcon from "@mui/icons-material/VerifiedOutlined";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import ImageIcon from "@mui/icons-material/Image";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { Divider } from "@mui/material";
import Grow from "@mui/material/Grow";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import IconButton from "@mui/material/IconButton";
import OrderPage from "./Admin/order";
import KhachHangPage from "./Admin/khachhang";
import DashBoardPage from "./Admin/dashboard";
import { useNavigate } from "react-router-dom";

const drawerWidth = 180;
const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "center",
    fontSize: "26px",
    fontWeight: "bold",
    fontFamily: "serif",
    color: "blue",
  }));
function HomePage(){
  let navigate = useNavigate();
    const [selectedItem, setSelectedItem] = useState("Item 1");
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  useEffect(() => {
    const storedValue = localStorage.getItem("TMAdminToken");
    if (!storedValue) {
      navigate("/login");
    }

  }, []);
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
      };
      const handleItemClick = (item) => {
        setSelectedItem(item);
      };
      const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
          return;
        }
    
        setOpen(false);
      };
      function handleListKeyDown(event) {
        if (event.key === "Tab") {
          event.preventDefault();
          setOpen(false);
        } else if (event.key === "Escape") {
          setOpen(false);
        }
      }
      React.useEffect(() => {
        // ref.current.ownerDocument.body.scrollTop = 0;
      }, []);
    return(
        <div>
            <Drawer
            variant="permanent"
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              [`& .MuiDrawer-paper`]: {
                width: drawerWidth,
                boxSizing: "border-box",
              },
            }}
          >
            <DrawerHeader style={{ fontSize: 25, fontWeight: 800 }}>
              TM Software
            </DrawerHeader>
            {/* <Toolbar /> */}
            <Box sx={{ overflow: "auto", height: "92vh" }}>
              <nav aria-label="main mailbox folders">
                <List>
                  <ListItem
                    button
                    onClick={() => handleItemClick("Item 1")}
                    disablePadding
                    sx={{
                      backgroundColor:
                        selectedItem === "Item 1" ? "grey" : "white",
                      color: selectedItem === "Item 1" ? "white" : "black",
                    }}
                  >
                    <ListItemButton>
                      <ListItemIcon style={{ minWidth: 40 }}>
                        <DashboardIcon />
                      </ListItemIcon>
                      <ListItemText primary="Tổng quan" />
                    </ListItemButton>
                  </ListItem>
                  <ListItem
                    disablePadding
                    sx={{
                      backgroundColor:
                        selectedItem === "Item 2" ? "grey" : "white",
                      color: selectedItem === "Item 2" ? "white" : "black",
                    }}
                    onClick={() => handleItemClick("Item 2")}
                  >
                    <ListItemButton>
                      <ListItemIcon style={{ minWidth: 40 }}>
                        <LocalMallIcon />
                      </ListItemIcon>
                      <ListItemText primary="Đơn hàng" />
                    </ListItemButton>
                  </ListItem>

                  <ListItem
                    disablePadding
                    sx={{
                      backgroundColor:
                        selectedItem === "Item 3" ? "grey" : "white",
                      color: selectedItem === "Item 3" ? "white" : "black",
                    }}
                    onClick={() => handleItemClick("Item 3")}
                  >
                    <ListItemButton>
                      <ListItemIcon style={{ minWidth: 40 }}>
                      <SupportAgentOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText primary="Khách hàng" />
                    </ListItemButton>
                  </ListItem>
                </List>
              </nav>
            </Box>
            <div>
              <Divider style={{ paddingBottom: "3px" }} />
              <List
                sx={{
                  width: "100%",
                  bgcolor: "background.paper",
                }}
              >
                <ListItem style={{ fontSize: 12, fontWeight: 900 }}>
                  <ListItemAvatar
                    ref={anchorRef}
                    style={{ minWidth: 45 }}
                    onClick={handleToggle}
                  >
                    <Avatar
                      sx={{
                        width: 35,
                        height: 35,
                        ":hover": {
                          cursor: "pointer",
                        },
                      }}
                    >
                      <ImageIcon />
                    </Avatar>
                  </ListItemAvatar>

                  <ListItemText primary="Admin" style={{ fontSize: 12 }} />
                </ListItem>
              </List>
              <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                placement="bottom-start"
                transition
                disablePortal
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin:
                        placement === "bottom-start"
                          ? "left top"
                          : "left bottom",
                    }}
                  >
                    <Paper style={{backgroundColor:'teal'}}>
                      <ClickAwayListener onClickAway={handleClose}>
                        <MenuList
                          autoFocusItem={open}
                          id="composition-menu"
                          aria-labelledby="composition-button"
                          onKeyDown={handleListKeyDown}
                        >
                          <MenuItem onClick={handleClose} style={{color:'white'}}>Profile</MenuItem>
                          <MenuItem onClick={handleClose} style={{color:'white'}}>My account</MenuItem>
                          <MenuItem onClick={handleClose} style={{color:'white'}}>Đăng xuất</MenuItem>
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </div>
          </Drawer>
          {selectedItem==="Item 1" && <DashBoardPage/>}
          {selectedItem==="Item 2" && <OrderPage/>}
          {
            selectedItem ==="Item 3" && <KhachHangPage/>
          }
        </div>
    );
}
export default HomePage;